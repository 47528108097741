import { parse } from 'fast-xml-parser'
import { appSocket } from '../../config/App'
import { Http } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'
import axios from 'axios'
import swal from 'sweetalert'

const store = 2;
const tel = '5514997241400';
const code = '575238';
//const guide = '76BFEFF9-A9E0-45D0-BFDD-631130C1F262';
const guide = '38C8F1F1-D2D3-46D2-987D-D710197D515C';
const email = 'contato@brazilcats.com.br' 
//const token = '053AE8DDDC704E6FAB2C1A874AEBD593'
const token = 'E882B2F7A5384444B0197A7BDA2FF4DE'
var hash = 0;
export var bandeiras = [];

export const actionTypes = {
    CHANGE: 'PAY_CHANGE',
    SESSION: 'PAY_SESSION',
    SUCCESS: 'PAY_SUCCESS',
    ERROR: 'PAY_ERROR'
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const setSession = (payload) => ({
    type: actionTypes.SESSION,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const alertError = (value) => dispatch => {
    dispatch(changeLoading({ open: false }))
    dispatch(changeNotify({
        open: true,
        msg: Object.values(value)[0],
        class: 'error'
    }))
    dispatch(error(value))
}

export const GenerateSession = () => dispatch => {

    var data = {
        'email': email,
        'token' : token
    };
    
    dispatch(changeLoading({
        open: true,
        msg: 'Gerando sessão...'
    }))

	return axios
	.post("https://comendus.com.br/api/pagseguro/session", {
        "body" : data,
        "Content-Type": "application/xml; charset=utf-8"
	 })
	.then(function(response) {

        let obj = parse(response.data);
        let fcode = obj.session.id;

        dispatch(changeLoading({open: false}))
        dispatch(setSession(fcode))

        window.PagSeguroDirectPayment.setSessionId(fcode);

        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: 500.00,
            success: function(response) {
                var obj = response.paymentMethods.CREDIT_CARD.options;
                
                //var userNames = Object.keys(obj);
                //console.log (userNames);
        
                bandeiras = Object.keys(obj).map((key) => [key, obj[key]]);
                
                //bandeiras = response.paymentMethods.CREDIT_CARD.options;
                //console.log (bandeiras);
                // Retorna os meios de pagamento disponíveis.
            },
            error: function(response) {
                console.log (response);
                // Callback para chamadas que falharam.
            },
            complete: function(response) {
                //console.log (response);
                // Callback para todas chamadas.
            }
        });

        window.PagSeguroDirectPayment.onSenderHashReady(function(response){
            if(response.status === 'error') {
                console.log(response.message);
                return false;
            }
            hash = response.senderHash; //Hash estará disponível nesta variável.
            console.log ('hash: ' + hash);
        });

 	})
	.catch(function(error) {
		console.log(error);
	});

    //paratestes (data);
}


export const getSessionPagSeguro = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    return Http.post('/pagseguro/session', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            dispatch(success(res.data.id))

            console.log(res.data);

            var reference = data['product_id'];
            var status = res.data.payment.status_detail;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fim', urlpec : res.data.payment.transaction_details.external_resource_url }))
            novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${res.data.payment.description} - ${res.data.payment.payer.identification.number} - ${res.data.payment.payer.email}`, res.data.payment.transaction_amount,reference);

            swal("Boleto gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            if(erro.response) {
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}

export const payCard = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    data['emailtoken'] = email;
    data['token'] = token;
    data['hash'] = hash;
    return Http.post('/pay/card/ps', data)
        .then(res => {

            dispatch(changeLoading({open: false}))
            let obj = parse(res.data.payment);

            dispatch(success(obj.transaction.code))

            console.log(data);
            console.log(res.data);
            
            var reference = data['product_id'];
            var status = obj.transaction.status;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fimcard' }))
            novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${obj.transaction.sender.name} - ${obj.transaction.code} - ${obj.transaction.sender.email}`, obj.transaction.grossAmount, reference);

            swal("Pagamento efetuado com sucesso!", {
                icon: "success",
                timer: 2000
                });
    
        })
        .catch(erro => {
            console.log (erro)
            if(erro.response) {
                console.log (erro)
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const payPec = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    data['emailtoken'] = email;
    data['token'] = token;
    data['hash'] = hash;
    return Http.post('/pay/pec/ps', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            let obj = parse(res.data.payment);

            dispatch(success(obj.transaction.code))
            console.log(obj);
            
            var reference = data['product_id'];
            var status = obj.transaction.status;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fim', urlpec : obj.transaction.paymentLink }))
            novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${obj.transaction.sender.name} - ${obj.transaction.code} - ${obj.transaction.sender.email}`, obj.transaction.grossAmount, reference);

            swal("Boleto gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            console.log(erro);
            if(erro.response) {
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const payEft = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    data['emailtoken'] = email;
    data['token'] = token;
    data['hash'] = hash;
    return Http.post('/pay/eft/ps', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            let obj = parse(res.data.payment);

            dispatch(success(obj.transaction.code))
            console.log(obj);

            console.log (res.data);

            var reference = data['product_id'];
            var status = obj.transaction.status;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fimeft', urlpec : obj.transaction.paymentLink }))
            novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${obj.transaction.sender.name} - ${obj.transaction.code} - ${obj.transaction.sender.email}`, obj.transaction.grossAmount, reference);

            swal("Código gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            console.log(erro);
            if(erro.response) {
                if(erro.response.data.msg) {
                 
                    if (erro.response.data.msg === "The customer can't be equal to the collector.") {
                        erro.response.data.msg = "O Comprador não pode ser o vendedor! Altere o e-mail e/ou cpf."
                    }

                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const payPix = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    data['emailtoken'] = email;
    data['token'] = token;
    data['hash'] = hash;
    return Http.post('/pay/pix/ps', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            let obj = parse(res.data.payment);

            dispatch(success(obj.transaction.code))
            console.log(obj);

            console.log (res.data);

            var reference = data['product_id'];
            var status = obj.transaction.status;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fime', urlpec : obj.transaction.paymentLink }))
            novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${obj.transaction.sender.name} - ${obj.transaction.code} - ${obj.transaction.sender.email}`, obj.transaction.grossAmount, reference);

            swal("Código gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            console.log(erro);
            if(erro.response) {
                if(erro.response.data.msg) {
                 
                    if (erro.response.data.msg === "The customer can't be equal to the collector.") {
                        erro.response.data.msg = "O Comprador não pode ser o vendedor! Altere o e-mail e/ou cpf."
                    }

                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const checarPagamento = (data) => dispatch => {
    console.log (data);
    return Http.post('api/pay/check', data)
        .then(res => {
            //dispatch(changeLoading({open: false}))
            //dispatch(success(res.data.id))

            console.log (res.data);

        })
        .catch(erro => {
            if(erro.response) {
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}

function enviarnotificacao(data, status) {

    switch(status) {
        case 'accredited':
            status = 'Pago'
          break;
        case 'pending_waiting_transfer':
            status = 'Aguardando Pagamento'
          break;
        case 'pending_waiting_payment':
            status = 'Aguardando Pagamento'
          break;
        default:                   
      }

    if (!data.first_name) {
        data.first_name = data.cardholderName;
        data.last_name = '';
    }

    var message = 'Você recebeu uma notificação de pedido. Cliente: ' + data.first_name + ' ' + data.last_name + ' Valor: ' + data.amount + ' reais. Whatsapp: ' + data.cel + ' Situação do Pedido: ' +  status; 
    //enviarmsg(tel, code, message); DESLIGADO

}



function enviarmsg(phone, code, msg) {

    //axios.get(`https://api.callmebot.com/whatsapp.php?phone=${phone}&apikey=${code}&text=${msg}`)
    //responseType: 'json',
    //.then(res => {
    //    console.log (res.data);
    //})
    
    axios ({
        method: 'GET',
        url: `https://api.callmebot.com/whatsapp.php?source=web&phone=+${phone}&apikey=${code}&text=${msg}`,
        //data: {'id':this.props.id},
        dataType: 'jsonp',
        cors: true ,
        contentType:'application/json',
        secure: true,
        headers: {                  
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization", 
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE" ,
            "Content-Type": "application/json;charset=UTF-8"                   
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader ("Authorization", "Basic " + btoa(""));
        },         
        transformRequest: [(data, headers) => {
            return data;
        }]
    }).then(function (response) {

        console.log (response.data);

    }).catch(function (error) {

        console.log('Error: ', error);
    });



}
 
function novopedido(user_id,name,value,reference) {

    var d = new Date();
    var month = d.getMonth() +1;
    var year = d.getFullYear();

    axios ({
        method: 'POST',
        url: 'https://comendus.com.br/api/billings',
        data: {'user_id': user_id, 'store_id': store, reference: reference, 'guid' : guide, 'automatic': 1, 'name' : name, 'month' : month, 'year': year, 'credits': [{'name' : 'Pedido', 'type' : 1, 'value' : value}], 'debts': [{'name' : 'Taxa', 'type' : 2, 'value' : 0.25, 'status' : 'A PAGAR'}] },
        dataType: 'json',
        contentType:'application/json',
    }).then(function (response) {

        console.log (response.data);

        appSocket.emit("enviar mensagem", {
            origem: "Jefferson",
            msg: 'Pedido realizado ' + store,
            reference: reference,
            store: store
          });

    }).catch(function (error) {

        console.log('Error: ', error);
    });

}




